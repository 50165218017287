@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes rotateAnimation {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes rotateBackAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}


$fade-in: fadeIn .4s;
$fade-in-longer: fadeIn 1s;
$fade-in-longest: fadeIn 1.5s;
$rotate-180: rotateAnimation .4s ease-in-out forwards;
$rotate-0: rotateBackAnimation .4s ease-in-out forwards;
