@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#login-section {
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
}

.open {
    padding-top: 7em;
}

.login-wrapper-div {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(132, 116, 116, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3 ease;
    margin: 1em;
    margin-left: 10px;
    width: auto;
    height: calc(100vh - 2.5em);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    text-align: center;
    text-wrap: wrap;
}

.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
