@use '../../styles/colors.scss';

.update-album-name-form-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
  
.update-album-name-form-modal-content {
    text-align: center;
    margin: 17vh auto;
    padding: 3em;
    width: 500px;
    height: fit-content;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: auto;
    &.food-order {
        height: 600px;
        margin-top: 1vh;
    }

    h2 {
        margin-bottom: 20px;
        color: colors.$main-font;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .update-album-name-form-modal-content {
        margin: 4vh auto;
        height: 700px;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .update-album-name-form-modal-content {
        margin: 15vh auto;
        &.food-order {
            margin-top: 15vh;
        }
    }
}

@media only all and (max-width: 640px) {
    .update-album-name-form-modal-content {
        width: 300px;
        padding: 1.5em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }
}
