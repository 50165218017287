@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#loading-section {
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
}

.open {
    padding-top: 7em;
}

.loading-wrapper-div {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(132, 116, 116, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3 ease;
    margin: 1em;
    margin-left: 10px;
    width: auto;
    height: calc(100vh - 2.5em);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    text-align: center;
    text-wrap: wrap;
}

.loading-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all .4s ease-in-out;

    img {
        margin-top: 4em;
        width: 450px;
        transition: all .4s ease-in-out;
    }

    div {
        width: 100%;
        padding: 1em;
        transition: all .4s ease-in-out;

        h1 {
            width: 100%;
            padding: 0.5em 2em;
            font-size: 40px;
            transition: all .4s ease-in-out;
        }
    }

    h4 {
        margin: 1em 1em 0;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 1em;
    }
}
