@use '../../styles/colors.scss';

.styled-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: colors.$button-nav-hover;
    border-left: 4px solid colors.$nav-border-left;
    cursor: pointer;
    color: colors.$hover-font;
  }
}

.label-div {
  span {
      margin-left: 16px;
      font-family: 'Exo', sans-serif;
  }
}

.dropdown-link {
  background: colors.$button-subnav;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  
  &:hover {
    background: colors.$button-nav-hover;
    border-left: 4px solid colors.$nav-border-left;
    cursor: pointer;
    color: colors.$hover-font;
  }
}

.icons {
  transform: translateY(-2px);
  margin-right: 15px;
}
