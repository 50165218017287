@use '../../styles/colors.scss';

.attendance-code-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
  
.attendance-code-modal-content {
    text-align: center;
    margin: 2vh auto;
    padding: 3em;
    width: 90%;
    height: 900px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: auto;
    transition: width 0.3s ease-in-out;

    h1 {
        font-size: 75px;
        transition: width 0.3s ease-in-out;
        &.large {
            font-size: 250px;
        }
    }
}

.modal-close {
    &.large {
        top: 7%;
        right: 9%;
        svg {
            width: 75px;
            height: auto;
        }
    }
}

.loading-code-large {
    width: 200px;
    height: auto;
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .attendance-code-modal-content {
        margin: 8vh auto;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .attendance-code-modal-content {
        margin: 25vh auto;
        width: 650px;
        height: fit-content;
    }

    .modal-close {
        &.large {
            top: 27%;
            right: 20%;
        }

        &.small {
            top: 26vh !important;
            right: 20vw !important;
        }
    }
}

@media only all and (max-width: 640px) {
    .attendance-code-modal-content {
        width: 300px;
        height: fit-content;
        padding: 1.5em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }
}