@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use '../../styles/fonts.scss';

#admin-dashboard-section {
    float: right;
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
}

.admin-dashboard-wrapper-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: all 0.3 ease;
}

.admin-dashboard-wrapper-div {
    width: auto;
    height: calc(100vh - 8.25em);
    padding: 1em 3em;
    margin: 1em 1em 1em 10px;
    overflow: none;
    text-wrap: wrap;
}

.admin-dashboard-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

    &.grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto 1fr;
        gap: 5px;
        padding: 10px;
        margin: 1em auto;
    }

    h1 {
        justify-self: center;
        width: 100%;
        padding: 1em 0;
    }
}

.admin-card-button {
    width: 27vw;
    height: 50vh;
    padding: 0 10px;
    margin: 0 auto;
    margin-bottom: 1em;
    background: colors.$button-admin-dashboard;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    border: 1.5px solid rgba(0, 0, 0, 0.5);
    color: colors.$main-font;
    transition: all .25s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0 3em;
    }

    svg {
        width: 125px;
        height: auto;
    }

    &.open {
        width: 24vw;
    }

    &:hover {
        background-color: colors.$button-admin-dashboard-hover;
        h1 {
            color: colors.$hover-font;
        }
    }

    &:active {
        background-color: colors.$button-admin-dashboard-active;
    }

    &.disabled {
        background-color: colors.$button-disabled;
        &:hover {
            cursor: not-allowed;
            h1 {
                color: colors.$main-font;
            }
        }
    }
}

// @media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {

// }

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .admin-dashboard-wrapper-div {
        height: calc(100vh - 12em);
    }

    .admin-card-button {
        height: 27vw;
        svg {
            width: 100px;
        }
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .admin-dashboard-wrapper-div {
        padding: 10px;
    }

    .admin-dashboard-wrapper-div {
        padding: 10px;
        margin-bottom: 0;
        height: calc(100vh - 7.5em);
        max-height: 70em;
    }

    .admin-card-button {
        height: 27vw;
        svg {
            width: 100px;
        }
    }
}

@media only all and (max-width: 640px) {
    #admin-dashboard-section {
        float: none;
        margin: 0;
        padding: 0;
    }

    .admin-dashboard-wrapper-div {
        margin: 1em;
        padding: 1em;
        height: fit-content;
    }

    .admin-dashboard-content {
        width: 300px;
        flex-direction: column;
        &.grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .admin-card-button {
        width: 250px;
        height: 250px;
        margin: 1em auto;

        svg {
            width: 80px;
        }
    }
}
