@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

#attendance-form-div, #food-order-forms-div, #admin-tools-dashboard, #media-dashboard {
    width: fit-content;
    height: 100%;

    h2 {
        margin: 1em 0;
    }
}

#media-dashboard {
    .wrapper-div {
        margin-bottom: 2em;
    }

    #create-album-button {
        margin-left: 0;
    }
}

#media-card {
    margin: 10px auto;
    padding: 1em;
    width: 30vw;
}

#media-management-card {
    margin: 10px auto;
    padding: 1em;
    width: fit-content;
}

#data-table-wrapper {
    margin: 0 auto;
}

#refresh-table {
    margin-bottom: 1em;
}

.refresh-oval {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    transform: translateY(3px)
}

.status-oval {
    text-align: center;
    color: colors.$main-font !important;
    padding: 5px 20px;
    border-radius: 20px;
    margin: 10px 20px;

    &.open {
        background-color: colors.$success-color;
        border-color: colors.$success-color;
    }

    &.closed {
        background-color: colors.$error-color;
        border-color: colors.$error-color;
    }
}

.reload {
    width: 20px;
    height: auto;
    transform: translateY(5px);
    cursor: pointer;

    &:hover {
        fill: colors.$hover-font;
    }
}

.info-div {
    border-top: none;
    margin: 0 auto;
}

.oval-div {
    padding: 1em;

    svg {
        width: 100px;
        height: auto;
    }
}

.create-button-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
}

.under-construction-div {
    div {
        img {
            width: 300px;
            border-radius: 10px;
        }   
    }

    h1 {
        padding: 0 !important;
        margin-bottom: 10px;
    }
    
    p {
        margin: 0;
    }
}

.data-table-div {
    width: 700px;
}

.search-div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1em;

    .select-div {
        margin: 0 5px;
    }
}

.chip-div {
    width: 700px;
}

.roster-action-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        margin-right: 0;
    }
}

#create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 25px;
        height: auto;
        transform: translateY(-2px);
    }
}

#full-filter {
    color: colors.$main-font;
}

#current-roster-div {
    overflow: auto;
}

.MuiSelect-outlined {
    &.MuiSelect-outlined {
        &.table-action {
            height: 35px;
            margin: 5px auto;
            &.light {
                svg {
                    fill: colors.$main-font;
                }  

                fieldset {
                    border-color: colors.$main-font;
                }
            }

            &.dark {
                color: colors.$secondary-font;
                svg {
                    fill: colors.$secondary-font;
                }  
            }
        }

        &.search-select {
            color: colors.$main-font;
            width: 125px;
            svg {
                fill: colors.$main-font;
            }  

            fieldset {
                border-color: colors.$main-font;
            }
        }
    }
}

.MuiChip-root {
    &.MuiChip-root {
        &.info-table {
            height: 30px;
            margin: 1em auto;
            color: colors.$main-font;
            background-color: colors.$info-color;
            border-color: colors.$info-color;

            svg {
                transform: translateY(-1px);
            }
        }
    }
}

.snow {
    fill: colors.$main-font;
}

.search-box .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: colors.$main-font;
}

.search-box .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: colors.$hover-font;
}

.search-box .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: colors.$main-font;
}

.search-box .MuiOutlinedInput-input {
    color: colors.$main-font;
}

.search-box .MuiInputLabel-root {
    color: colors.$main-font;
}

.search-box .MuiInputLabel-root.Mui-focused {
    color: colors.$main-font;
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    #attendance-form-div, #food-order-forms-div {
        width: 90%;
    }
}
  
@media only all and (max-width: 640px) {
    #attendance-form-div, #food-order-forms-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #admin-tools-dashboard {
        width: 300px;
        height: calc(100vh - 18em);
    }

    #data-table-wrapper {
        width: 300px;
        overflow: auto;
    }

    #media-card {
        width: 300px;
    }

    #media-management-card {
        width: 300px !important;
        overflow: auto !important;
    }

    .under-construction-div {
        div {
            img {
                width: 200px;
            }
        }
    }

    .email-table-div {
        width: 300px;
    }

    .create-button-div {
        width: 300px;
    }

    #create-button {
        margin-right: 0;
    }

    .MuiBox-root {
        &.MuiBox-root {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .search-div {
        flex-direction: column;
        align-items: flex-start;

        button {
            margin-left: 0;
        }
    }

    .search-div {
        .select-div {
            margin: 0;
            margin-top: 1em;
        }
    }

    .chip-div {
        width: 300px;
        display: flex;
        justify-content: flex-start;
    }

    #full-filter {
        float: left;
    }

    .roster-action-button {
        display: flex;
        justify-content: flex-start;
        
        button {
            margin-left: 0;
        }
    }

    .MuiSelect-outlined {
        &.MuiSelect-outlined {
            &.search-select {
                width: 300px;
                margin-left: 0;
            }
        }
    }
}
