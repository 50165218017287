@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use '../../styles/fonts.scss';

#photos-section {
    margin: 1em 1em 1.5em 10px;
    padding: 0;
    float: right;
    transition: width 0.3s ease-in-out;
    animation: animations.$fade-in;
    // border-radius: 10px;
    overflow: auto;
}

#photo-album-header-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 0 1em 0 10px;
    height: auto;
}

#photos-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    height: auto;
}

.photos-title,
.photos-title-album,
.photos-wrapper-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: all 0.3 ease;
}

.photos-title-album {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin: 0;
    padding: 1em 0;
    width: 100%;
    text-align: center;
}

.photos-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1em;
    position: relative;

    .main-button {
        margin-left: 1em;
        position: absolute;
        left: 0;
    }
    
    h1 {
        margin: 0 auto;
    }
    
}

.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.photos-wrapper-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    min-height: calc(100vh - 7.5em);
    padding: 1em 3em;
    margin: 1em 1em 0 10px;
    overflow: auto;
    text-wrap: wrap;
}

.photos-content {
    width: 100%;
    height: 100%;
    margin: 1em 0 2em 0;
    padding: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    h2 {
        justify-self: center;
        width: 100%;
        padding: 1em 0;
    }
}

#loading-photos-icon {
    width: 100px;
    height: auto;
}

.image-wrapper {
    position: relative;
    margin: 10px;
    display: inline-block;
    background-color: colors.$secondary-font;
    border-radius: 10px;
}

.thumbnail {
    display: block;
    width: 100%;
    transition: opacity 0.3s ease-in-out;
}

.enlarge-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: colors.$bg1;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.image-wrapper:hover .thumbnail {
    opacity: 0.4;
    cursor: pointer;
}

.image-wrapper:hover .enlarge-overlay {
    opacity: 1;
}

.filler {
    width: 27vw;
    height: 45vh;
    margin: 5px;
    border: none;
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .filler {
        height: 29vh;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .filler {
        width: 275px;
        height: 250px;
        margin: 10px;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .filler {
        width: 275px;
        height: 250px;
        margin: 10px;
    }
}

@media only all and (max-width: 640px) {
    #photos-section {
        margin: 1em auto;
        width: 95%;
        float: none;
    }

    .photos-title {
        justify-content: flex-end;
    }

    .photos-wrapper-div {
        padding: 0;

        h3 {
            margin-top: 10px;
        }
    }

    .photos-content {
        flex-direction: column;
    }

    .photos-title {
        justify-content: flex-end;
        flex-direction: row;
        height: 60px;
        
        .main-button {
            position: relative;
            margin: 0;
        }
    }

    #photos-header-wrapper {
        padding: 0;
        margin: 0 1em 0 10px;
    }
}
