@use '../../styles/colors.scss';

.enlarge-image-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
  
.enlarge-image-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5vh auto;
    padding: 3em;
    width: 65vw;
    height: 90vh;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.enlarged-wrapper {
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.enlarged {
    width: auto;
    height: 100%;
    border-radius: 10px;
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .enlarge-image-modal-content {
        margin: 5vh auto;
        width: fit-content;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .enlarge-image-modal-content {
        width: fit-content;
        height: fit-content;
    }

    .enlarged-wrapper {
        img {
            width: 900px;
            height: auto;
        }
    }

    .modal-close {
        top: 16vh !important;
        right: 52vh !important;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .enlarge-image-modal-content {
        margin: 25vh auto;
        width: fit-content;
        height: fit-content;
    }

    .enlarged-wrapper {
        img {
            width: 700px;
            height: auto;
        }
    }
}

@media only all and (max-width: 640px) {
    .enlarge-image-modal {
        overflow: auto;
    }
    .enlarge-image-modal-content {
        margin: 22vh auto;
        width: 390px;
        height: fit-content;
        padding: 1.5em;
        padding-top: 3em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }

    .enlarged-wrapper {
        img {
            width: 350px;
            height: auto;
        }
    }
}

@media only all and (max-height: 640px) {
    .enlarge-image-modal {
        overflow: auto;
    }
    .enlarge-image-modal-content {
        margin: 0 auto;
        width: 500px;
        height: fit-content;
        padding: 1.5em;
        padding-top: 3em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }

    .enlarged-wrapper {
        img {
            width: 450px;
            height: auto;
        }
    }
}
