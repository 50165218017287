@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#donate-section {
    margin: 1em 1em 0 10px;
    padding: 0;
    float: right;
    transition: width 0.3s ease-in-out;
    animation: animations.$fade-in;
    // border-radius: 10px;
    overflow: auto;
}

.donate-title {
    margin: 0;
    margin-bottom: 1em;
    width: auto;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    h1 {
        margin: 14px auto;
        font-size: 60px;
    }
}

.donate-wrapper-div {
    height: calc(100vh - 22.25em);
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    margin: 0;
    overflow: auto;
    text-wrap: wrap;
}

.donate-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-align: left;
        line-height: 1.75;
        margin: 1em auto;
    }

    hr {
        width: 90%;
        margin: 3em auto;
        border: 2px solid white;
        border-radius: 5px;
    }

    div {
        h1 {
            font-size: 50px;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .donate-content-center {
        p {
            width: fit-content;
            text-align: center;
            line-height: 1.75;
            margin: 1.5em auto;
            padding: 0 5%;
            white-space: nowrap;

            a {
                text-decoration: underline;
                font-weight: bold;
                &:hover {
                    color: colors.$hover-font !important;
                }
            }
        }
    }

    .donate-content-split {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: top;

        .split-left {
            width: 39%;
            height: 100%;
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;

                a {
                    text-decoration: underline;
                    font-weight: bold;
                    &:hover {
                        color: colors.$hover-font;
                    }
                }
            }

            img {
                margin-top: 1.5em;
                width: 425px;
            }
        }

        .split-right {
            width: 60%;
            height: 100%;
            padding: auto 0;
            h2 {
                margin-top: 1.5em;
            }
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;
            }
        }
    }
}

.portal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

#portal-title {
    margin-top: 2em;
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .donate-title {
        height: 250px;
    }

    .donate-wrapper-div {
        height: calc(100vh - 19.1em);
    }

    .donate-content {
        .donate-content-split {
            margin-top: 1em;

            .split-left {
                img {
                    width: 600px;
                }
            }
        }
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) {
    .donate-wrapper-div {
        padding: 10px;
        margin-right: 1em;
        margin-bottom: 0;
        height: calc(100vh - 7.5em);
        max-height: 69.3em;
    }
}


@media only all and (max-width: 640px) {
    #donate-section {
        margin: 1em auto;
        width: 95%;
        float: none;
        background: rgba(0, 0, 0, 0.6);
    }

    .donate-title {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 !important;
        height: 150px;

        h1 {
            font-size: 25px !important;
        }
        
        h2 {
            font-size: 20px !important;
        } 
    }

    .donate-wrapper-div {
        padding: 0;
        height: fit-content;
    }

    .donate-content {
        margin: 1em !important;
        padding: 0 !important;

        h1 {
            margin-top: 0 !important;
            font-size: 30px !important;
        }
        
        h2 {
            font-size: 25px !important;
        }

        .donate-content-split {
            flex-direction: column;

            .split-left {
                margin: 0 auto;
                width: fit-content;
                img {
                    margin: 0;
                    width: 300px;
                }
            }

            .split-right {
                width: 100% !important;
                margin: 0 !important;
                h2 {
                    margin-top: 10px;
                }

                p {
                    margin: 1em;
                    margin-bottom: 0;
                }
            }
        }
    }

    .portal-buttons {
        flex-direction: column;
    }

    #portal-title {
        margin-top: 1.5em;
    }
}
