#sign-attendance-wrapper {
    h1 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    h4 {
        margin: 10px 0;
    }
}

.back-button-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
}

.chip-div {
    margin: 1em 0;
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    #sign-attendance-wrapper {
        width: 775px;
        margin: 0 3em;
    }
}

@media only all and (max-width: 640px) {
    #sign-attendance-wrapper {
        width: 300px;
        margin: 0 auto;
    }

    #attendance-table {
        width: 300px;
    }

    .main-button {
        &.table-button {
            padding: 0 5px !important;
            margin:  5px 0;
        }
    }

    .back-button-div {
        .main-button {
            margin-right: 0;
        }
    }
}
