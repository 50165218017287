@use '../../styles/colors.scss';

.dash-card {
    width: 27vw;
    height: 45vh;
    padding: 0 10px;
    margin-right: 1em;
    background: colors.$card-bg;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: colors.$secondary-font;
    overflow: auto;
    transition: all .35s ease-in-out;

    &.open {
        width: 24vw;
    }
}

.album-card {
    width: 27vw;
    height: 45vh;
    padding: 0 10px;
    margin: 5px;
    background: colors.$card-bg;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: colors.$secondary-font;
    overflow: auto;
    transition: all .35s ease-in-out;

    h2 {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: fit-content;
        overflow: clip;
    }

    &.open {
        width: 24vw;
    }

    &:hover {
        background-color: colors.$card-bg-hover;

        img {
            opacity: .6;
            transition: all .35s ease-in-out;
        }
    }
}

.dash-card-content {
    padding: 0;
    transition: all .35s ease-in-out;

    h2 {
        margin: 10px auto;
    }

    &.album {
        h2 {
            padding: 0;
        } 
    }
}

.main-button {
    &.dashcard {
        margin: 0;
        margin-top: 5px;
        padding: 5px 10px;
        font-size: 80%;
    }

    &.edit-account {
        margin: 1em 0 1.5em 0;
        padding: 5px 10px;
        font-size: 80%;
        display: flex;
    }

    &.tools-button {
        width: 100%;
        height: 3.5em;
        margin: 5px 0;

        &:first-child {
            margin-top: 0;
        }
    }
}

.info-box {
    padding: .25rem 1em;
    margin: 2rem 1rem;
    background: rgba(196, 196, 196, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(196, 196, 196, 0.96);

    div {
        margin: 2rem 0;
    }

    h4 {
        color: colors.$secondary-font;
        margin-top: 5px;
    }

    p {
        text-align: left;
        color: colors.$secondary-font;
        margin: 5px 1.5rem;
    }

    li {
        text-align: left;
        margin: 0;
        margin-left: 2.5em;
        
        &:first-child {
            margin-top: 10px;
        }

        &:last-child {
            margin-bottom: 10px;
        }
    }

    th, td {
        font-weight: normal;
    }

    a {
        color: colors.$secondary-font;
        font-weight: normal;
        text-decoration: underline;
    }

    a:hover {
        color: colors.$secondary-hover-font;
        text-decoration-color: colors.$secondary-hover-font;
    }
}

.contact-box {
    padding: 0;
    margin: 2em 0;
    width: 100%;
    background: rgba(196, 196, 196, 0.26);
    border-radius: 16px;
    border: 1px solid rgba(196, 196, 196, 2);

    h4 {
        color: colors.$secondary-font;
        margin-top: 5px;
        margin-bottom: 0;
    }

    p {
        margin: 5px 1rem;
        padding: 5px 0;
    }
}

.required {
    text-decoration: underline;
    font-weight: bolder;
}

.thumbnail {
    width: 375px;
    height: 250px;
    margin: 0 auto;
    border-radius: 10px;
    object-fit: cover;

    &.holder {
        border: 2px solid colors.$secondary-font;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .album-card {
        height: 29vh;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .dash-card {
        margin: 1em;
    }

    .album-card {
        width: 36vw;
        height: 28vh;
    }

    #list-card-tools, #list-card-account {
        margin-top: 3em;
        height: 450px;
    }

    #list-card-info {
        height: 450px;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .dash-card {
        margin: 10px;
    }
}

@media only all and (max-width: 640px) {
    .dash-card {
        width: 100%;
        margin: 1em auto;
    }

    .dash-card-content {
        padding: 0 10px;
    }

    .info-box {
        margin: 2em .25em;
    }

    .album-card {
        width: 300px;
    }
}
