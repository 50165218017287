@use '../../styles/colors.scss';

.alert-stack-div {
    position: fixed;
    bottom: 35px;
    left: 35px;
    z-index: 1000;
}

@media only all and (max-width: 640px) {
    .alert-stack-div {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
