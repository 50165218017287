@use '../../styles/colors.scss';

.view-form {
    width: 80%;
}

.details {
    margin: 1em;
    text-align: left;

    p {
        margin: 5px 0;
        svg {
            transform: translateY(2px);
            cursor: pointer;
            &:hover {
                color: colors.$secondary-hover-font;
            }
        }
    }
}

.loading-code {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.color-key-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
}

.color-square {
    width: 1em;
    height: 1em;
    margin-right: 5px;
    border: 1px solid colors.$main-font;
    transform: translateY(-2px);
    &.green {
        background-color: colors.$success-color;
    }

    &.red {
        background-color: colors.$error-color;
    }
}

.page-actions {
    display: flex;
    justify-content: space-between;
}

.main-button {
    &.details {
        font-size: 70%;
        padding: 5px 10px;
        margin: 0;
    }
}

#form-title {
    padding: 0;
}

#view-form-content {
    margin: 1em auto;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

#refresh-table {
    width: fit-content;
    float: right;
}

#roster-title {
    margin: 2em 0 1em;
}

@media only all and (max-width: 640px) {
    .view-form {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .table-div {
        width: 300px;
        overflow: auto;
    }

    .page-actions {
        flex-direction: column;
        justify-content: flex-start;
    }
}
