@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#auditions-section {
    margin: 1em 1em 0 10px;
    padding: 0;
    margin-bottom: 1.5em;
    float: right;
    transition: width 0.3s ease-in-out;
    animation: animations.$fade-in;
    // border-radius: 10px;
    overflow: auto;
}

.auditions-title {
    margin: 0;
    margin-bottom: 1em;
    width: auto;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    h1 {
        margin: 14px auto;
        font-size: 60px;
    }
}

.auditions-wrapper-div {
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    margin: 0;
    overflow: auto;
    text-wrap: wrap;
}

.auditions-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-align: left;
        line-height: 1.75;
        margin: 1em auto;
    }

    hr {
        width: 90%;
        margin: 3em auto;
        border: 2px solid white;
        border-radius: 5px;
    }

    div {
        h1 {
            font-size: 50px;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .auditions-content-center {
        p {
            text-align: left;
            line-height: 1.75;
            margin: 1.5em auto;
            padding: 0 5%;
        }
    }

    .auditions-content-split {
        margin: 0 5%;
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .split-left {
            width: 50%;
            height: 100%;
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;

                a {
                    text-decoration: underline;
                    font-weight: bold;
                    &:hover {
                        color: colors.$hover-font;
                    }
                }
            }
        }

        .split-right {
            width: 50%;
            height: 100%;
            padding: auto 0;
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;
            }
        }
    }

    #interest-form {
        margin: 3em auto;
    }
}

#join-img {
    width: 550px;
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) {
    .auditions-wrapper-div {
        padding: 10px;
        margin-right: 1em;
        margin-bottom: 0;
        height: calc(100vh - 7.5em);
        max-height: 69.3em;
    }
}

@media only all and (max-width: 640px) {
    #auditions-section {
        float: none;
        margin: 1em;
    }

    .auditions-title {
        height: 150px;
        h1 {
            font-size: 25px;
        }

        h2 {
            font-size: 17px;
        }
    }

    .auditions-wrapper-div {
        padding: 1em;
    }

    .auditions-content {
        width: fit-content;
        .auditions-content-split {
            flex-direction: column;
            .split-left {
                width: 100%;
            }

            .split-right {
                width: 100%;
                padding: 0;
            }
        }

        .main-button {
            font-size: 20px;
        }
    }

    #join-img {
        width: 300px;
        margin-left: auto;
    }
}
