@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use '../../styles/fonts.scss';

#dashboard-section {
    float: right;
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
}

#dashboard-header-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 1em 1em 0 10px;
    height: auto;
}

#admin-dashboard-header-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 1em 1em 0 10px;
    height: auto;
}

.profile-wrapper,
.dashboard-title,
.dashboard-actions,
.dashboard-wrapper-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: all 0.3 ease;
}

.profile-wrapper {
    width: fit-content;
    height: auto;
    margin-right: .5em;
    background: colors.$bg1;

    div {
        text-align: left;
        white-space: nowrap;
    }

    img {
        width: 45px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 1em;
    }

    h1 {
        white-space: nowrap;
        span {
            font-family: fonts.$title-font;
            font-weight: bold;
        }

        a {
            font-family: fonts.$title-font;
            font-weight: 100;

            &:hover {
                color: colors.$hover-font;
                font-weight: bold;
            }

            &:active {
                color: colors.$active-font;
            }
        }
    }
}

.dashboard-title {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin: 0 0.5em;
    text-align: center;
}

.dashboard-wrapper-div {
    width: auto;
    height: calc(100vh - 8.25em);
    padding: 1em 3em;
    margin: 1em 1em 1em 10px;
    overflow: none;
    text-wrap: wrap;
}

.dashboard-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    h1 {
        justify-self: center;
        width: 100%;
        padding: 1em 0;
    }
}

.title-button:hover {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.title-button:active {
    background: rgba(255, 255, 255, 0.8);
    h1 {
        color: colors.$active-font;
    }
}

.title-button h1 {
    padding: 0 10px;
}

.dashboard-actions {
    margin: 0 0.5em;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
        white-space: nowrap;
    }

    button {
        white-space: nowrap;
        margin: 0 5px;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .dashboard-title {
        width: 100% !important;
        margin-left: 0;
    }
    .dashboard-actions {
        width: 50%;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .dashboard-title {
        width: 50% !important;
        margin-left: 0;
    }
    .dashboard-actions {
        width: 50%;
    }
    .dashboard-wrapper-div {
        height: calc(100vh - 12em);
    }
    .dashboard-content > * {
        flex: 1 1 30%;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .dashboard-company-title,
    .dashboard-title,
    .dashboard-actions,
    .dashboard-wrapper-div {
        padding: 10px;
    }

    .dashboard-wrapper-div {
        align-items: center;
        padding: 10px;
        margin-bottom: 0;
        height: calc(100vh - 7.5em);
        max-height: 63em;
    }

    .dashboard-content {
        height: fit-content;
        flex-wrap: wrap;
        margin: 0 3em;
    }

    .dashboard-content > * {
        flex: 1 1 40%;
    }
}

@media only all and (max-width: 640px) {
    #dashboard-section {
        float: none;
        margin: 0;
        padding: 0;
    }

    #dashboard-header-wrapper {
        margin: 1em;
        padding: 0;
    }

    #admin-dashboard-header-wrapper {
        margin: 1em;
        padding: 0;
    }

    .dashboard-title {
        width: 100%;
        margin: 0;
    }

    .dashboard-actions {
        margin: 0 1em;
        padding: 10px;

        label {
            display: none;
        }

        button {
            white-space: nowrap;
        }
    }

    .dashboard-wrapper-div {
        margin: 1em;
        padding: 1em;
        height: fit-content;
    }

    .dashboard-content {
        width: 300px;
        flex-direction: column;
    }
}
