@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#contact-section {
    margin: 1em 1em 0 10px;
    padding: 0;
    margin-bottom: 1.5em;
    float: right;
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
    // border-radius: 10px;
    overflow: auto;
}

.contact-title {
    margin: 0;
    margin-bottom: 1em;
    padding: 0 5em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: 300px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    h1 {
        margin: 14px auto;
    }

    #contact-title-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;

        img {
            width: 300px;
            margin: 1.5em auto;
        }
    }

    #contact-title-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;

        h1 {
            font-size: 75px;
        }
    }
}

.contact-wrapper-div {
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: all 0.3 ease;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    margin: 0;
    overflow: auto;
    text-wrap: wrap;
}

.contact-content {
    width: 100%;
    height: 100%;
    margin: 0 5em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        margin: 1em;
    }

    .contact-split-left {
        width: 50%;
        height: 100%;
        p {
            text-align: left;
            margin: 1.5em 0;
            line-height: 1.75;
        }
    }

    .contact-split-right {
        width: 50%;
        height: 100%;
        padding: auto 0;

        p {
            text-align: left;
            margin: 1.5em 0;
            line-height: 1.75;
        }

        .logo {
            width: 200px;
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .social-icon {
            width: 75px;
            margin: 15px 20px;
        }
    }
}

@media all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .contact-split-right {
        .logo {
            width: 300px !important;
        }
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .contact-wrapper-div {
        padding: 10px;
        margin-bottom: 0;
        height: calc(100vh - 27.5em);
    }
}

@media only all and (max-width: 640px) {
    #contact-section {
        margin: 1em;
        float: none;
    }

    .contact-title {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 1em;
        h1 {
            margin: 5px 0;
        }

        #contact-title-left {
            width: 100%;
            img {
                width: 200px;
                margin-bottom: 0;
            }
        }

        #contact-title-right {
            width: 100%;
            h1 {
                font-size: 25px;
            }
        }
    }

    .contact-wrapper-div {
        padding: 1em;
    }

    .contact-content {
        flex-direction: column;
        .contact-split-left {
            width: 100%;
        }
    
        .contact-split-right {
            width: 100%;
            margin-bottom: 2em;
            h2 {
                font-size: 20px;
            }
    
            .logo {
                width: 200px;
                margin-top: 25px;
                margin-bottom: 15px;
            }
    
            .social-icon {
                width: 70px;
                margin: 15px 10px;
            }
        }
    }
}
