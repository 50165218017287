.image-loader-wrapper {
    position: relative;
    width: 375px;
    height: 250px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s ease-in-out;
    }

    .fade-in {
        opacity: 1;
    }

    .hidden {
        opacity: 0;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .image-loader-wrapper {
        width: 500px;
        height: 375px;
    }
}


@media only all and (min-device-width: 641px) and (max-device-width: 1024px) {
    .image-loader-wrapper {
        width: 275px;
        height: 250px;
    }
}

@media only all and (max-width: 640px) {
    .image-loader-wrapper {
        width: 275px;
        height: 250px;
        margin: 0 auto;
    }
}
