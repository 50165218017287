@use '../../styles/colors.scss';

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    svg {
        width: 35px;
        height: auto;
        transform: translate(0);
        fill: colors.$hover-font;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);

        svg {
            fill: colors.$main-font;
        }
    }

    &:active {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    }
}

.main-button {
    &.modal {
        margin: 10px 2.5px;
        margin-bottom: 0;
        width: 7em;
        white-space: nowrap;
        text-wrap: none ;
    }
}

.outline-button {
    margin: 10px 2.5px;
    margin-bottom: 0;
    width: 7em;

    &.modal {
        width: 7.5em;
        padding: 10.5px 20px;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {

}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .modal-close {
        margin-top: -14.5vh;
        left: 79vw !important;
        width: fit-content;
        height: fit-content;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .modal-close {
        margin-top: -14.5vh;
        left: 90.5vw !important;
        width: fit-content;
        height: fit-content;
    }
}

@media only all and (max-width: 640px) {
    .main-button {
        &.modal {
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }
}
