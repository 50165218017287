@use '../../styles/colors.scss';

.MuiTab-textColorPrimary {
    &.MuiTab-textColorPrimary {
        color: colors.$main-font;
        font-family: 'Avenir Heavy';
        &.Mui-selected {
            color: colors.$main-font;
            background-color: colors.$selected-tab-bg-color;
            font-weight: bolder;
            border-radius: 10px;
        }
    }
}

.MuiTabs-indicator {
    &.MuiTabs-indicator {
        background: linear-gradient(180deg, transparent 50%, colors.$selected-tab-color 50%);
        height: 10px;
        border-radius: 0 0 10px 10px;
    }
}

.MuiTab-iconWrapper {
    &.MuiTab-iconWrapper {
        &.tab-icon {
            width: 35px;
            height: auto;
        }
    }
}

.MuiBox-root {
    &.MuiBox-root {
        &.tab-nav {
            width: fit-content;
            margin: 0 auto;
            padding: 0 10px;
            background: colors.$tab-nav-bg;
            border-radius: 10px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-bottom: 1;
        }
    }
}

@media only all and (max-width: 640px) {
    .MuiBox-root {
        &.MuiBox-root {
            &.tab-nav {
                width: 300px;
            }
        }
    }
}
