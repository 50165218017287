@use '../../styles/colors.scss';

.base-TablePagination-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border: 1px solid colors.$main-border;
    border-top: none;
    background-color: colors.$table-bg;
    padding: 10px;
}

.base-TablePagination-selectLabel {
    margin: 0;
}

.base-TablePagination-displayedRows {
    margin: 0;
    margin-left: auto;
}

.base-TablePagination-spacer {
    display: none;
}

.base-TablePagination-actions {
    display: flex;
    gap: 0.25rem;
}

@media only all and (max-width: 640px) {
    .base-TablePagination-toolbar {
        height: 75px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }

    .base-TablePagination-toolbar > * {
        flex: 1 1 5px;
    }

    .base-TablePagination-toolbar > *:nth-last-child(-n+2) {
        margin-left: auto;
    }

    .base-TablePagination-displayedRows {
        margin: 0;
    }
}
  