@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use '../../styles/fonts.scss';

#about-section {
    margin: 1em 1em 1.5em 10px;
    padding: 0;
    float: right;
    transition: width 0.3s ease-in-out;
    animation: animations.$fade-in;
    // border-radius: 10px;
    overflow: auto;
}

.about-title {
    margin: 0;
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: 300px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    h1 {
        margin: 14px auto;
    }

    #about-title-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;

        img {
            width: 300px;
            margin: 1.5em auto;
        }
    }

    #about-title-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;

        h1 {
            font-size: 55px;
        }
    }
}

.about-wrapper-div {
    background: colors.$bg1;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    margin: 0;
    text-wrap: wrap;
    will-change: height;
}

.about-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-align: left;
        line-height: 1.75;
        margin: 1em auto;
    }

    hr {
        width: 90%;
        margin: 3em auto;
        border: 2px solid white;
        border-radius: 5px;
    }

    div {
        h1 {
            font-size: 50px;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .about-content-center {
        p {
            text-align: left;
            line-height: 1.75;
            margin: 1.5em auto;
            padding: 0 5%;
        }

        #who-we-are-img {
            width: fit-content;
            margin: 0 auto;

            img {
                width: 700px;
            }

            .caption {
                text-align: right;
                font-family: fonts.$title-font;
                font-size: 15px;
                margin: 0;
                padding: 0;
            }
        }

        &.faqs {
            margin: 4em 5%;
            background-image: url('../../assets/faq.jpg');
            background-position: center;
            background-repeat: none;
            background-size: cover;
            border-radius: 10px;
            #faq-content {
                background: rgba(128, 0, 0, 0.7);
                border-radius: 10px;
                box-shadow: 0 4px 30px rgba(132, 116, 116, 0.1);
                border: 1px solid rgba(255, 255, 255, 0.3);
                h1 {
                    margin: 14px auto;
                }

                h4 {
                    margin: 0;
                    padding: 0 5%;
                    text-align: left;
                }

                p {
                    margin-top: 5px;
                    line-height: normal;
                }
            }
        }
    }

    #about-drumline {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        h1 {
            margin-top: 0;
        }
    }

    .about-content-split {
        margin: 0 5%;
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .split-left {
            width: 50%;
            height: 100%;
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;
            }
        }

        .info {
            h2, h4 {
                text-align: left;
            }

            p {
                text-align: left !important;
                padding: 0 !important;
                margin: 0 !important;
                margin-bottom: 1em !important; 
                a {
                    text-decoration: underline;
                    font-weight: bold;
                    &:hover {
                        color: colors.$hover-font;
                    }
                }
                
                .email {
                    text-decoration: none;
                    font-weight: normal;
                }
            }
        }

        .split-right {
            width: 50%;
            height: 100%;
            padding: auto 0;
            p {
                text-align: left;
                margin: 1.5em 0;
                line-height: 1.75;
            }
        }
    }
}

#director-img {
    width: 400px;
}

#instructor-img {
    width: 475px;
    margin-left: -3em;
}

#dl-director-img {
    width: 400px;
}

#webmaster-img {
    width: 500px;
    margin-left: -3em;
}

#webmaster {
    padding-bottom: 3em;
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .about-content-split {
        p {
            margin-left: 0;
        }
    }

    .about-content-center {
        margin: 0 5%;
        padding-top: 1em;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.faqs {
            padding: 0;
        }
    }

    #who-we-are-img {
        img {
            width: 575px !important;
        }
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .about-wrapper-div {
        padding: 10px;
        margin-bottom: 0;
        height: calc(100vh - 27.5em);
    }
}

@media only all and (max-width: 640px) {
    #about-section {
        margin: 1em auto;
        width: 95%;
        float: none;
    }

    .about-title {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 !important;
        height: 350px;

        h1 {
            font-size: 30px !important;
        }
        
        h3 {
            font-size: 15px !important;
        }

        div {
            width: 100% !important;
            padding: 0 2em !important;

            img {
                margin: 10px 0 !important;
                width: 200px !important;
            }
        }   
    }

    .about-wrapper-div {
        padding: 0;
    }

    .about-content {
        margin: 1em !important;
        padding: 0 !important;
        width: 100% !important;

        h1 {
            font-size: 30px !important;
        }
        
        h2 {
            font-size: 25px !important;
        }

        .about-content-center {
            img {
                width: 300px !important;
            }
        }

        .about-content-split {
            flex-direction: column;

            .split-left {
                width: 100% !important;
                margin: 0 !important;
            }

            .split-right {
                width: 100% !important;
                margin: 0 !important;
            }
        }
    }

    #director-img {
        width: 300px;
    }

    #instructor-img {
        width: 300px;
        margin-left: 0;
    }
    
    #dl-director-img {
        width: 300px;
    }
    
    #webmaster-img {
        width: 300px;
        margin-left: 0;
    }

    #webmaster {
        padding-bottom: 0;
    }
}
