@use '../../styles/colors.scss';

.view-form {
    width: 80%;
    height: 100%;

    h3 {
        margin: 1em 0;
    }
}

.details {
    margin: 1em;
    text-align: left;

    p {
        margin: 5px 0;
        svg {
            transform: translateY(2px);
            cursor: pointer;
            &:hover {
                color: colors.$secondary-hover-font;
            }
        }
    }
}

.page-actions {
    display: flex;
    justify-content: space-between;
}

.main-button {
    &.details {
        font-size: 70%;
        padding: 5px 10px;
        margin: 0;
    }
}

.order-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    div {
        overflow: auto;
        background-color: colors.$accordion-bg;
        border: 1px solid colors.$secondary-border;
        border-radius: 15px;
        white-space: nowrap;
        padding: 10px;
        margin-right: 1em;

        h4 {
            color: colors.$secondary-font;
        }

        div {
            border: none;
            width: 100%;
            white-space: nowrap;
            padding: 5px;
            p {
                text-align: left;
                font-weight: normal;
            }

            .items {
                text-indent: 10px;
                font-weight: bold;
                color: colors.$error-color;
            }
        }

        #total-orders {
            span {
                color: colors.$error-color;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        p {
            padding-bottom: 0;
            font-weight: bold;
            color: colors.$secondary-font;
        }

        ul {
            margin-top: 5px;
            padding-left: 25px;
            color: colors.$secondary-font;
            li {
                text-align: left;
                margin: 0 10px;
                span {
                    font-weight: bold;
                }
            }
        }
    }
}

.count {
    color: colors.$error-color !important;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
}

#form-title {
    padding: 0;
}

#view-form-content {
    margin: 1em auto;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

#refresh-table {
    width: fit-content;
    float: right;
}

#export-button {
    margin-right: 0;
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) {
    .order-details {
        div {
            hyphens: auto;
            white-space: normal;
    
            div {
                white-space: normal;
            }
        }
    }
}

@media only all and (max-width: 640px) {
    .view-form {
        width: 100%;
    }
    
    .order-details {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div {
            width: fit-content;
            hyphens: auto;
            white-space: normal;
            margin: 1em 0;
            div {
                width: fit-content !important;
                white-space: normal;
            }
        }
    }

    .table-div {
        overflow: auto;
    }

    .page-actions {
        flex-direction: column;
        justify-content: flex-start;
    }

    #export-button {
        margin: 10px auto;
    }
}
