@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#not-found-section {
    transition: all 0.3s ease-in-out;
    animation: animations.$fade-in;
}

.not-found-wrapper-div {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(132, 116, 116, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3 ease;
    margin: 1em;
    margin-left: 10px;
    width: auto;
    height: calc(100vh - 2.5em);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    text-align: center;
    text-wrap: wrap;
}

.not-found-content {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    text-align: center;

    div {
        margin: 1em;
        margin-left: 3em;
    }

    img {
        margin-top: 4.5em;
        margin-right: 20px;
        margin-bottom: -25px;
        padding-right: 15px;
        width: 350px;
    }

    h1 {
        width: 100%;
        margin: auto;
    }

    h4 {
        margin: 1em 1em 0;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 1em;
    }

    #check-back {
        padding-bottom: 3em;
    }
}

@media only all and (max-width: 640px) {
    .not-found-wrapper-div {
        margin: 1em;
        height: calc(100vh - 7em);
    }

    .not-found-content {
        width: 300px;
        margin: 0 auto;
        div {
            margin: 10px;
            margin-bottom: 0;
        }

        img {
            margin: 4.5em auto;
            margin-bottom: 0;
            padding-right: 0;
            width: 250px;
        }

        h4 {
            margin: 0 auto;
        }

        p {
            margin: 0.5em;
        }

        #sys-admin {
            padding-bottom: 0;
        }
    }
}
