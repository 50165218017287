@use '../../styles/colors.scss';

.form-action-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
  
.form-action-modal-content {
    text-align: center;
    margin: 5vh auto;
    padding: 3em;
    width: 500px;
    height: fit-content;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: auto;

    h2 {
        margin-bottom: 20px;
        font-size: 35px;
        color: colors.$main-font;
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .form-action-modal-content {
        margin: 8vh auto;
    }
}

@media only all and (max-width: 640px) {
    .form-action-modal-content {
        width: 325px;
        margin-top: 10vh;
        padding: 2em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }

    .outline-button {
        &.modal {
            width: 7.5em;
            padding: 10.5px 15px;
        }
    }
}
