@use '../../styles/colors.scss';
@use '../../styles/animations.scss';

#project-section {
    float: right;
    transition: all 0.3s ease-in-out;
}

.project-title {
    margin: 1em;
    margin-left: 10px;
    width: auto;
    height: auto;
    text-align: center;

    h1 {
        margin: 0.5 auto;
        margin-top: 15px;
        font-size: 50px;
    }

    h3 {
        margin: 0.5em auto;
        margin-bottom: 15px;
    }
}

.project-title, .project-wrapper-div {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: all 0.3 ease;
}

.project-wrapper-div {
    width: auto;
    height: calc(100vh - 11.25em);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 3em;
    margin: 1em 1em 1em 10px;
    overflow: auto;
    text-wrap: wrap;
}

.project-content {
    width: 100%;
    height: 100%;
    text-align: center;

    iframe {
        margin: 10px auto;
    }

    div {
        margin: 1em;
        margin-left: 3em;
    }

    h4 {
        margin: 1em 1em 0;
    }

    a {
        text-decoration: underline;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 1em;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) {
    .project-wrapper-div {
        padding: 10px;
        margin-right: 1em;
        margin-bottom: 0;
        height: calc(100vh - 7.5em);
        max-height: 69.9em;
    }
}

@media only all and (max-width: 640px) {
    #project-section {
        float: none;
    }

    .project-title {
        padding: 0 1em;
        margin-left: 1em;
        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 15px;
        }
    }

    .project-wrapper-div {
        margin: 1em;
        padding: 1em 0;
    }
}
