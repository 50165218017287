@use '../../styles/colors.scss';

.delete-form-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
  
.delete-form-modal-content {
    text-align: center;
    margin: 15vh auto;
    padding: 3em;
    padding-bottom: 1em;
    width: fit-content;
    height: fit-content;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: auto;

    p {
        margin-bottom: 5px;
        &.underlined {
            font-weight: bold;
            text-decoration: underline;
        }

        strong {
            color: colors.$error-color;
            font-weight: bolder;
        }
    }
}

@media only all and (min-device-width: 1920px) and (max-device-width: 2559px) {
    .delete-form-modal-content {
        margin: 20vh auto;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape) {
    .delete-form-modal-content {
        width: 375px;
    }

    .modal-close {
        top: 16vh !important;
        right: 52vh !important;
    }
}

@media only all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait) {
    .delete-form-modal-content {
        width: 375px;
    }

    .modal-close {
        top: 16vh !important;
        right: 25.5vh !important;
    }
}

@media only all and (max-width: 640px) {
    .delete-form-modal-content {
        width: 300px;
        padding: 1.5em;

        h2 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }
}