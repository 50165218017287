@font-face {
    font-family: 'Avenir Heavy';
    src: local('AvenirNextLTPro-Heavy'),
        url('../fonts/AvenirNextLTPro-Heavy.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Regular';
    src: local('AvenirNextLTPro-Regular'),
        url('../fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Caslon';
    src: local('ACaslonPro-Regular'),
        url('../fonts/ACaslonPro-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

$main-font: 'Avenir Regular';
$secondary-font: 'Caslon';
$title-font: 'Avenir Heavy';