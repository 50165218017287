#sign-food-order-wrapper {
    h1 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    h4 {
        margin: 10px 0;
    }
}

.back-button-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
}

@media only all and (max-width: 640px) {
    .back-button-div {
        .main-button {
            margin-right: 0;

            &.table-button {
                padding: 0 5px !important;
            }
        }
    }

    .main-button {
        &.table-button {
            padding: 0 5px !important;
            margin:  5px 0;
        }
    }

    #sign-food-order-wrapper {
        width: 300px;
        overflow: auto;
    }

    #ord-data-table-wrapper {
        width: 300px;
    }
}
